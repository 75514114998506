:root {
  --white: #fff;
  --grey: #e6e6e6;
  --black: #262626;
  --green: #06847d;
  --blue: #4a69bd;
  --orange: #a46313;
  --red: #e02e06;
  --primary: var(--blue);
  --secondary: var(--green);
  --background: #fff;
  --background-alt: #f8fafc;
  --selection: #9e9e9e;
  --text-main: #4a4a4a;
  --text-bright: #000;
  --text-muted: #707070;
  --links: var(--primary);
  --focus: rgba(74, 105, 189, .35);
  --border: rgba(0, 0, 0, .2);
  --border-radius: 4px;
  --code: #000;
  --animation-duration: .1s;
  --button-hover: #ddd;
  --button-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  --form-placeholder: #949494;
  --form-text: #000;
  --variable: #39a33c;
  --highlight: #ff0;
  --select-arrow: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='62.5' width='116.9' fill='%23161f27'%3E%3Cpath d='M115.3 1.6c-1.6-1.6-4.2-1.6-5.8 0l-51 51.1L7.4 1.6C5.8 0 3.2 0 1.6 1.6 0 3.2 0 5.8 1.6 7.4l53.9 53.9c.8.8 1.8 1.2 2.9 1.2 1 0 2.1-.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2.1-5.8z'/%3E%3C/svg%3E");
  --heading-margin: .5rem 0 .75rem;
  --typo-size-1: 2.8rem;
  --typo-size-2: 2.2rem;
  --typo-size-3: 1.8rem;
  --typo-size-4: 1.4rem;
  --typo-size-5: 1.2rem;
  --typo-size-6: 1rem;

  --accent-border: 0;
  --accent-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}
/* Background */
[style*="--bg:"] {
    background: var(--bg) !important;
  }
[style*="--bga:"] {
    background-attachment: var(--bga) !important;
  }
[style*="--bgc:"] {
    background-color: var(--bgc) !important;
  }
/* @mixin svar-mq background-image, --bgi; */
/* @mixin svar-mq background-position, --bgp; */
/* @mixin svar-mq background-repeat, --bgr; */
/* @mixin svar-mq background-size, --bgs; */
/* @mixin svar:hover background, --hvr-bg;
@mixin svar:hover background-color, --hvr-bgc; */
/* [style*="--grad-top:"] {
    background-image: linear-gradient(0deg, transparent, var(--grad-bottom));
}

[style*="--grad-bottom:"] {
    background-image: linear-gradient(180deg, transparent, var(--grad-bottom));
} */
/* Border */
[style*="--b:"] {
    border: var(--b) !important;
  }
[style*="--bc:"] {
    border-color: var(--bc) !important;
  }
[style*="--bs:"] {
    border-style: var(--bs) !important;
  }
[style*="--bw:"] {
    border-width: var(--bw) !important;
  }
[style*="--br:"] {
    border-radius: var(--br) !important;
  }
[style*="--radius:"] {
    border-radius: var(--radius) !important;
  }
/* @mixin svar-mq border-top, --bt;
@mixin svar-mq border-left, --bl;
@mixin svar-mq border-right, --br;
@mixin svar-mq border-bottom, --bb;
@mixin svar-mq border-top-left-radius, --btlr;
@mixin svar-mq border-top-right-radius, --btrr;
@mixin svar-mq border-bottom-left-radius, --bblr;
@mixin svar-mq border-bottom-right-radius, --bbrr; */
/* @mixin svar:hover border, --hvr-b;
@mixin svar:hover border-color, --hvr-bc; */
/* Flex */
[style*="--fx:"] {
    flex: var(--fx) !important;
  }
[style*="--fd:"] {
    flex-direction: var(--fd) !important;
  }
[style*="--fw:"] {
    flex-wrap: var(--fw) !important;
  }
[style*="--ac:"] {
    align-content: var(--ac) !important;
  }
[style*="--ai:"] {
    align-items: var(--ai) !important;
  }
[style*="--as:"] {
    align-self: var(--as) !important;
  }
[style*="--jc:"] {
    justify-content: var(--jc) !important;
  }
/* @mixin svar-mq flex-grow, --fw;
@mixin svar-mq flex-shrink, --fs;
@mixin svar-mq flex-basis, --fb; */
[style*="--o:"] {
    order: var(--o) !important;
  }
/* Grid */
/* @mixin svar-mq grid-template-columns, --gtc;
@mixin svar-mq grid-template-rows, --gtr;
@mixin svar-mq grid-auto-columns, --gac;
@mixin svar-mq grid-auto-rows, --gar;
@mixin svar-mq grid-column, --gc;
@mixin svar-mq grid-row, --gr;
@mixin svar-mq grid-gap, --gg;
@mixin svar-mq place-self, --ps;


[style*="--grid-cols:"] {
    grid-template-columns: repeat(var(--grid-cols), minmax(0, 1fr));
} */
/* Interact */
/* @mixin svar user-select, --us;
@mixin svar outline, --oe;
@mixin svar pointer-events, --pe;
@mixin svar scroll-behavior, --sb;
@mixin svar cursor, --cur;
@mixin svar:hover cursor, --cur; */
/* Layout */
[style*="--d:"] {
    display: var(--d) !important;
  }
[style*="--ft:"] {
    float: var(--ft) !important;
  }
[style*="--pos:"] {
    position: var(--pos) !important;
  }
[style*="--top:"] {
    top: var(--top) !important;
  }
[style*="--left:"] {
    left: var(--left) !important;
  }
[style*="--right:"] {
    right: var(--right) !important;
  }
[style*="--bottom:"] {
    bottom: var(--bottom) !important;
  }
/* @mixin svar-mq object-fit, --objf;
@mixin svar-mq object-position, --objp;
@mixin svar-mq visibility, --v; */
[style*="--z:"] {
    z-index: var(--z) !important;
  }
/* @mixin svar overflow, --of;
@mixin svar overflow-x, --ofx;
@mixin svar overflow-y, --ofy; */
/* Sizing */
[style*="--w:"] {
    width: var(--w) !important;
  }
[style*="--h:"] {
    height: var(--h) !important;
  }
[style*="--minw:"] {
    min-width: var(--minw) !important;
  }
[style*="--minh:"] {
    min-height: var(--minh) !important;
  }
[style*="--maxw:"] {
    max-width: var(--maxw) !important;
  }
[style*="--maxh:"] {
    max-height: var(--maxh) !important;
  }
/* Spacing */
/* @mixin svar box-sizing, --bxs; */
[style*="--p:"] {
    padding: var(--p) !important;
  }
[style*="--pl:"] {
    padding-left: var(--pl) !important;
  }
[style*="--pr:"] {
    padding-right: var(--pr) !important;
  }
[style*="--pt:"] {
    padding-top: var(--pt) !important;
  }
[style*="--pb:"] {
    padding-bottom: var(--pb) !important;
  }
[style*="--m:"] {
    margin: var(--m) !important;
  }
[style*="--ml:"] {
    margin-left: var(--ml) !important;
  }
[style*="--mr:"] {
    margin-right: var(--mr) !important;
  }
[style*="--mt:"] {
    margin-top: var(--mt) !important;
  }
[style*="--mb:"] {
    margin-bottom: var(--mb) !important;
  }
/* Typography */
[style*="--c:"] {
    color: var(--c) !important;
  }
/* @mixin svar font-family, --ff; */
[style*="--size:"] {
    font-size: var(--size) !important;
  }
[style*="--fs:"] {
    font-style: var(--fs) !important;
  }
[style*="--weight:"] {
    font-weight: var(--weight) !important;
  }
[style*="--ls:"] {
    letter-spacing: var(--ls) !important;
  }
[style*="--lh:"] {
    line-height: var(--lh) !important;
  }
[style*="--lis:"] {
    list-style: var(--lis) !important;
  }
/* @mixin svar list-style-type, --list;
@mixin svar list-style-position, --lisp;
@mixin svar-mq text-align, --ta;
@mixin svar text-decoration, --td;
@mixin svar text-shadow, --ts;
@mixin svar text-transform, --tt;
@mixin svar-mq writing-mode, --wm;
@mixin svar vertical-align, --va; */
/* @mixin svar-mq white-space, --ws;
@mixin svar-mq word-break, --wb; */
[style*="--cc:"] {
    column-count: var(--cc) !important;
  }
[style*="--cg:"] {
    column-gap: var(--cg) !important;
  }
[style*="--cr:"] {
    column-rule: var(--cr) !important;
  }
[style*="--cs:"] {
    column-span: var(--cs) !important;
  }
/* @mixin svar-mq direction, --dir; */
/* [style*="--bi:"] {
    page-break-inside: var(--bi);
    break-inside: var(--bi);
} */
/* Transform */
/* @mixin svar-mq transform, --t;
@mixin svar-mq transform-origin, --to;
@mixin svar-mq transform-style, --tc; */
/* Animation */
/* @mixin svar-mq transition, --tn;
@mixin svar-mq transition-delay, --td;
@mixin svar-mq transition-duration, --tdn;
@mixin svar-mq transition-property, --tp;
@mixin svar-mq transition-timing-function, -ttf; */
/* Misc */
/* @mixin svar all, --all; */
/* @mixin svar-mq content, --ct; */
[style*="--op:"] {
    opacity: var(--op) !important;
  }
/* @mixin svar-mq box-shadow, --bs; */
/* @mixin svar clear, --cr;
@mixin svar content, --ct;
@mixin svar filter, --fr; */
@media only screen and (min-width:640px) {
    [style*="--b-sm:"] {
      border: var(--b-sm) !important;
    }
    [style*="--bc-sm:"] {
      border-color: var(--bc-sm) !important;
    }
    [style*="--bs-sm:"] {
      border-style: var(--bs-sm) !important;
    }
    [style*="--bw-sm:"] {
      border-width: var(--bw-sm) !important;
    }
    [style*="--br-sm:"] {
      border-radius: var(--br-sm) !important;
    }
    [style*="--radius-sm:"] {
      border-radius: var(--radius-sm) !important;
    }
    [style*="--fx-sm:"] {
      flex: var(--fx-sm) !important;
    }
    [style*="--fd-sm:"] {
      flex-direction: var(--fd-sm) !important;
    }
    [style*="--fw-sm:"] {
      flex-wrap: var(--fw-sm) !important;
    }
    [style*="--ac-sm:"] {
      align-content: var(--ac-sm) !important;
    }
    [style*="--ai-sm:"] {
      align-items: var(--ai-sm) !important;
    }
    [style*="--as-sm:"] {
      align-self: var(--as-sm) !important;
    }
    [style*="--jc-sm:"] {
      justify-content: var(--jc-sm) !important;
    }
    [style*="--o-sm:"] {
      order: var(--o-sm) !important;
    }
    [style*="--d-sm:"] {
      display: var(--d-sm) !important;
    }
    [style*="--pos-sm:"] {
      position: var(--pos-sm) !important;
    }
    [style*="--top-sm:"] {
      top: var(--top-sm) !important;
    }
    [style*="--left-sm:"] {
      left: var(--left-sm) !important;
    }
    [style*="--right-sm:"] {
      right: var(--right-sm) !important;
    }
    [style*="--bottom-sm:"] {
      bottom: var(--bottom-sm) !important;
    }
    [style*="--z-sm:"] {
      z-index: var(--z-sm) !important;
    }
    [style*="--w-sm:"] {
      width: var(--w-sm) !important;
    }
    [style*="--h-sm:"] {
      height: var(--h-sm) !important;
    }
    [style*="--p-sm:"] {
      padding: var(--p-sm) !important;
    }
    [style*="--pl-sm:"] {
      padding-left: var(--pl-sm) !important;
    }
    [style*="--pr-sm:"] {
      padding-right: var(--pr-sm) !important;
    }
    [style*="--pt-sm:"] {
      padding-top: var(--pt-sm) !important;
    }
    [style*="--pb-sm:"] {
      padding-bottom: var(--pb-sm) !important;
    }
    [style*="--m-sm:"] {
      margin: var(--m-sm) !important;
    }
    [style*="--ml-sm:"] {
      margin-left: var(--ml-sm) !important;
    }
    [style*="--mr-sm:"] {
      margin-right: var(--mr-sm) !important;
    }
    [style*="--mt-sm:"] {
      margin-top: var(--mt-sm) !important;
    }
    [style*="--mb-sm:"] {
      margin-bottom: var(--mb-sm) !important;
    }
    [style*="--c-sm:"] {
      color: var(--c-sm) !important;
    }
    [style*="--size-sm:"] {
      font-size: var(--size-sm) !important;
    }
    [style*="--ls-sm:"] {
      letter-spacing: var(--ls-sm) !important;
    }
    [style*="--lh-sm:"] {
      line-height: var(--lh-sm) !important;
    }
    [style*="--op-sm:"] {
      opacity: var(--op-sm) !important;
    }
}
@media only screen and (min-width:768px) {
    [style*="--b-md:"] {
      border: var(--b-md) !important;
    }
    [style*="--bc-md:"] {
      border-color: var(--bc-md) !important;
    }
    [style*="--bs-md:"] {
      border-style: var(--bs-md) !important;
    }
    [style*="--bw-md:"] {
      border-width: var(--bw-md) !important;
    }
    [style*="--br-md:"] {
      border-radius: var(--br-md) !important;
    }
    [style*="--radius-md:"] {
      border-radius: var(--radius-md) !important;
    }
    [style*="--fx-md:"] {
      flex: var(--fx-md) !important;
    }
    [style*="--fd-md:"] {
      flex-direction: var(--fd-md) !important;
    }
    [style*="--fw-md:"] {
      flex-wrap: var(--fw-md) !important;
    }
    [style*="--ac-md:"] {
      align-content: var(--ac-md) !important;
    }
    [style*="--ai-md:"] {
      align-items: var(--ai-md) !important;
    }
    [style*="--as-md:"] {
      align-self: var(--as-md) !important;
    }
    [style*="--jc-md:"] {
      justify-content: var(--jc-md) !important;
    }
    [style*="--o-md:"] {
      order: var(--o-md) !important;
    }
    [style*="--d-md:"] {
      display: var(--d-md) !important;
    }
    [style*="--pos-md:"] {
      position: var(--pos-md) !important;
    }
    [style*="--top-md:"] {
      top: var(--top-md) !important;
    }
    [style*="--left-md:"] {
      left: var(--left-md) !important;
    }
    [style*="--right-md:"] {
      right: var(--right-md) !important;
    }
    [style*="--bottom-md:"] {
      bottom: var(--bottom-md) !important;
    }
    [style*="--z-md:"] {
      z-index: var(--z-md) !important;
    }
    [style*="--w-md:"] {
      width: var(--w-md) !important;
    }
    [style*="--h-md:"] {
      height: var(--h-md) !important;
    }
    [style*="--p-md:"] {
      padding: var(--p-md) !important;
    }
    [style*="--pl-md:"] {
      padding-left: var(--pl-md) !important;
    }
    [style*="--pr-md:"] {
      padding-right: var(--pr-md) !important;
    }
    [style*="--pt-md:"] {
      padding-top: var(--pt-md) !important;
    }
    [style*="--pb-md:"] {
      padding-bottom: var(--pb-md) !important;
    }
    [style*="--m-md:"] {
      margin: var(--m-md) !important;
    }
    [style*="--ml-md:"] {
      margin-left: var(--ml-md) !important;
    }
    [style*="--mr-md:"] {
      margin-right: var(--mr-md) !important;
    }
    [style*="--mt-md:"] {
      margin-top: var(--mt-md) !important;
    }
    [style*="--mb-md:"] {
      margin-bottom: var(--mb-md) !important;
    }
    [style*="--c-md:"] {
      color: var(--c-md) !important;
    }
    [style*="--size-md:"] {
      font-size: var(--size-md) !important;
    }
    [style*="--ls-md:"] {
      letter-spacing: var(--ls-md) !important;
    }
    [style*="--lh-md:"] {
      line-height: var(--lh-md) !important;
    }
    [style*="--op-md:"] {
      opacity: var(--op-md) !important;
    }
}
@media only screen and (min-width:1024px) {
    [style*="--b-lg:"] {
      border: var(--b-lg) !important;
    }
    [style*="--bc-lg:"] {
      border-color: var(--bc-lg) !important;
    }
    [style*="--bs-lg:"] {
      border-style: var(--bs-lg) !important;
    }
    [style*="--bw-lg:"] {
      border-width: var(--bw-lg) !important;
    }
    [style*="--br-lg:"] {
      border-radius: var(--br-lg) !important;
    }
    [style*="--radius-lg:"] {
      border-radius: var(--radius-lg) !important;
    }
    [style*="--fx-lg:"] {
      flex: var(--fx-lg) !important;
    }
    [style*="--fd-lg:"] {
      flex-direction: var(--fd-lg) !important;
    }
    [style*="--fw-lg:"] {
      flex-wrap: var(--fw-lg) !important;
    }
    [style*="--ac-lg:"] {
      align-content: var(--ac-lg) !important;
    }
    [style*="--ai-lg:"] {
      align-items: var(--ai-lg) !important;
    }
    [style*="--as-lg:"] {
      align-self: var(--as-lg) !important;
    }
    [style*="--jc-lg:"] {
      justify-content: var(--jc-lg) !important;
    }
    [style*="--o-lg:"] {
      order: var(--o-lg) !important;
    }
    [style*="--d-lg:"] {
      display: var(--d-lg) !important;
    }
    [style*="--pos-lg:"] {
      position: var(--pos-lg) !important;
    }
    [style*="--top-lg:"] {
      top: var(--top-lg) !important;
    }
    [style*="--left-lg:"] {
      left: var(--left-lg) !important;
    }
    [style*="--right-lg:"] {
      right: var(--right-lg) !important;
    }
    [style*="--bottom-lg:"] {
      bottom: var(--bottom-lg) !important;
    }
    [style*="--z-lg:"] {
      z-index: var(--z-lg) !important;
    }
    [style*="--w-lg:"] {
      width: var(--w-lg) !important;
    }
    [style*="--h-lg:"] {
      height: var(--h-lg) !important;
    }
    [style*="--p-lg:"] {
      padding: var(--p-lg) !important;
    }
    [style*="--pl-lg:"] {
      padding-left: var(--pl-lg) !important;
    }
    [style*="--pr-lg:"] {
      padding-right: var(--pr-lg) !important;
    }
    [style*="--pt-lg:"] {
      padding-top: var(--pt-lg) !important;
    }
    [style*="--pb-lg:"] {
      padding-bottom: var(--pb-lg) !important;
    }
    [style*="--m-lg:"] {
      margin: var(--m-lg) !important;
    }
    [style*="--ml-lg:"] {
      margin-left: var(--ml-lg) !important;
    }
    [style*="--mr-lg:"] {
      margin-right: var(--mr-lg) !important;
    }
    [style*="--mt-lg:"] {
      margin-top: var(--mt-lg) !important;
    }
    [style*="--mb-lg:"] {
      margin-bottom: var(--mb-lg) !important;
    }
    [style*="--c-lg:"] {
      color: var(--c-lg) !important;
    }
    [style*="--size-lg:"] {
      font-size: var(--size-lg) !important;
    }
    [style*="--ls-lg:"] {
      letter-spacing: var(--ls-lg) !important;
    }
    [style*="--lh-lg:"] {
      line-height: var(--lh-lg) !important;
    }
    [style*="--op-lg:"] {
      opacity: var(--op-lg) !important;
    }
}
@media only screen and (min-width:1280px) {
    [style*="--b-xl:"] {
      border: var(--b-xl) !important;
    }
    [style*="--bc-xl:"] {
      border-color: var(--bc-xl) !important;
    }
    [style*="--bs-xl:"] {
      border-style: var(--bs-xl) !important;
    }
    [style*="--bw-xl:"] {
      border-width: var(--bw-xl) !important;
    }
    [style*="--br-xl:"] {
      border-radius: var(--br-xl) !important;
    }
    [style*="--radius-xl:"] {
      border-radius: var(--radius-xl) !important;
    }
    [style*="--fx-xl:"] {
      flex: var(--fx-xl) !important;
    }
    [style*="--fd-xl:"] {
      flex-direction: var(--fd-xl) !important;
    }
    [style*="--fw-xl:"] {
      flex-wrap: var(--fw-xl) !important;
    }
    [style*="--ac-xl:"] {
      align-content: var(--ac-xl) !important;
    }
    [style*="--ai-xl:"] {
      align-items: var(--ai-xl) !important;
    }
    [style*="--as-xl:"] {
      align-self: var(--as-xl) !important;
    }
    [style*="--jc-xl:"] {
      justify-content: var(--jc-xl) !important;
    }
    [style*="--o-xl:"] {
      order: var(--o-xl) !important;
    }
    [style*="--d-xl:"] {
      display: var(--d-xl) !important;
    }
    [style*="--pos-xl:"] {
      position: var(--pos-xl) !important;
    }
    [style*="--top-xl:"] {
      top: var(--top-xl) !important;
    }
    [style*="--left-xl:"] {
      left: var(--left-xl) !important;
    }
    [style*="--right-xl:"] {
      right: var(--right-xl) !important;
    }
    [style*="--bottom-xl:"] {
      bottom: var(--bottom-xl) !important;
    }
    [style*="--z-xl:"] {
      z-index: var(--z-xl) !important;
    }
    [style*="--w-xl:"] {
      width: var(--w-xl) !important;
    }
    [style*="--h-xl:"] {
      height: var(--h-xl) !important;
    }
    [style*="--p-xl:"] {
      padding: var(--p-xl) !important;
    }
    [style*="--pl-xl:"] {
      padding-left: var(--pl-xl) !important;
    }
    [style*="--pr-xl:"] {
      padding-right: var(--pr-xl) !important;
    }
    [style*="--pt-xl:"] {
      padding-top: var(--pt-xl) !important;
    }
    [style*="--pb-xl:"] {
      padding-bottom: var(--pb-xl) !important;
    }
    [style*="--m-xl:"] {
      margin: var(--m-xl) !important;
    }
    [style*="--ml-xl:"] {
      margin-left: var(--ml-xl) !important;
    }
    [style*="--mr-xl:"] {
      margin-right: var(--mr-xl) !important;
    }
    [style*="--mt-xl:"] {
      margin-top: var(--mt-xl) !important;
    }
    [style*="--mb-xl:"] {
      margin-bottom: var(--mb-xl) !important;
    }
    [style*="--c-xl:"] {
      color: var(--c-xl) !important;
    }
    [style*="--size-xl:"] {
      font-size: var(--size-xl) !important;
    }
    [style*="--ls-xl:"] {
      letter-spacing: var(--ls-xl) !important;
    }
    [style*="--lh-xl:"] {
      line-height: var(--lh-xl) !important;
    }
    [style*="--op-xl:"] {
      opacity: var(--op-xl) !important;
    }
}
